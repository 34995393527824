import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    product: JSON.parse(localStorage.getItem('selected-product')) || {} 
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProduct: (state, action) =>{
            state.product = action.payload;  
            localStorage.setItem('selected-product', JSON.stringify(state.product));
          
        }       
    }
})

export const {setProduct} = productSlice.actions;
export default productSlice.reducer;