import React, {useState, useEffect, Fragment} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Button, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import { removeItem, setMealType, setQuantity, emptyCart } from './plateSlice';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';

import { useMutation } from "react-query";
import apiClient from "../../request/http-common";
import WalletButton from '../Wallet/WalletButton';
import { useNavigate } from 'react-router-dom';
import { setDinings } from '../../request/Dining/diningSlice';


const PlateContent = () => {
  const navigate = useNavigate();
  const contents = useSelector((state) => state.plate.items);
  const user = useSelector((state) => state.user.user);
  const subscriptions = useSelector((state) => state.subscriptions.subscriptions);
  const fundingBal = useSelector((state) => state.wallet.fundingBalance);
  const refBal = useSelector((state) => state.wallet.referralBalance);
  const [error, setErros] = useState('');
  const [promoMessage, setPromoMessage] = useState('Apply Promo Code');

  const dispatch = useDispatch(); 
  const formatPrice = (amount) => new Intl.NumberFormat().format(amount);
  const [total, setTotal] = useState(0);
  const [paymentSource, setPaymentSource] = useState('');
  const [address, setAddress] = useState(user.address);
  const [phone, setPhone] = useState(user.phone);
  const [extraInfo, setExtraInfo] = useState('');


const { isLoading: isOrdering, mutate: storeOrder } = useMutation(
  async () => {
    return await apiClient.post(`/api/save-order`, {
      content: contents,
      subscription_id: paymentSource,
      amount: total,
      phone: phone,
      address: address,
      other_details: extraInfo
    });
    
  },
  {
    onSuccess: (res) => {
        if (res.data.status === 'success') {
          dispatch(setDinings(res.data.dinings));
          localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
          setTimeout(() => {
            const event = new Event('newMessage');
            window.dispatchEvent(event);
          }, 1000);
          

          const event2 = new Event('processed');
          window.dispatchEvent(event2);

          contents.forEach(product => {
            dispatch(emptyCart(product));
          });

          setTimeout(() => {
            navigate('/dining');
          }, 2000);
          
        }            
    },
    onError: (err) => {
      let errorMessage = err.response?.data || err ;
      let myerror = err.response?.data || err;         
      setErros(myerror.errors)
      
      if (!myerror.errors) {
        localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
        const event = new Event('newMessage');
        window.dispatchEvent(event);
      }
      
      const event4 = new Event('processed');
      window.dispatchEvent(event4);
      
    },
  }
);

  const calculatePromo = (code) => {
    switch (code) {
      case 'MOUAU2024':
        setTotal(0.9*total);
        setPromoMessage('Promo code applied')
        break;

      case 'ABSU2024':
        setTotal(0.9*total);
        setPromoMessage('Promo code applied')
      break;

      default:
        if (contents.length > 0) {
          let count = 0;
          contents.forEach(content => {
            if (content.quantity === undefined) {
              count += parseFloat(content.unit_price)
            }else{
              let num = parseInt(content.quantity);
              let sum = num * parseFloat(content.unit_price);
              count += sum;
            }
            
          });
          setTotal(count);
          setPromoMessage('Invalid Promo Code')
        }
        break;
    }
  }


  useEffect(() => {
      
  if (contents.length > 0) {
    let count = 0;
    contents.forEach(content => {
      if (content.quantity === undefined) {
        count += parseFloat(content.unit_price)
      }else{
        let num = parseInt(content.quantity);
        let sum = num * parseFloat(content.unit_price);
        count += sum;
      }
      
    });
    setTotal(count);
  }

  }, [contents]);

  return (
    <div style={{marginTop: '70px'}}>

      {contents.length < 1 &&
        <Box mx={2} mt={10}>
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="info">Your plate is empty, kindly add items to your plate to eat something!</Alert>
        </Stack>
        </Box>
      }
      
      {contents.length > 0 &&
    <Grid container px={2} mt={4} mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={8}>
      <Grid container mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {contents.map(product=>(
        <Grid key={product.id} item xs={12} md={6}>
        <Card sx={{paddingBottom: '5px'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginX: '5px', borderRadius: '10px', marginBottom: '10px' }}>
            
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 14  }} gutterBottom variant="subtitle2" component="div">
                {product.name}
            </Typography>
            <Typography color="secondary" sx={{ fontWeight: 'bold', fontSize: 16   }} gutterBottom variant="button" component="div">
                    &#8358;{product.unit_price}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Chip color='primary' label={product.meal_type} />
              {/* <Chip label="Chip Outlined" variant="outlined" /> */}
            </Stack>            
            </CardContent>            
            </Box>

            <CardMedia
            component="img"
            sx={{ width: 200 }}
            image={product.product_image}
            alt="food"
            />
          </Box>

          <CardActions sx={{justifyContent: 'space-between'}}>
                <Button color='error' onClick={() => dispatch(removeItem(product))} sx={{ textTransform: 'capitalize' }} variant="outlined" startIcon={<DeleteIcon />}>
                    Remove
                </Button>
                <ButtonGroup ml={1} aria-label="small button group">
                  <Button onClick={()=>{
                    if (product.quantity === undefined) {
                      dispatch(setQuantity({id: product.id, qty: 1}))
                    }else{
                      if (parseInt(product.quantity) > 1) {
                        dispatch(setQuantity({id: product.id, qty: parseInt(product.quantity) - 1}))
                      }
                      
                    }
                   
                  }}  key="deduct"><RemoveIcon/></Button>
                  <Button key="quantity">{product.hasOwnProperty('quantity') ? `${product.quantity}`: 1}</Button>
                  <Button onClick={()=>{
                    if (product.quantity === undefined) {
                      dispatch(setQuantity({id: product.id, qty: 2}))
                    }else{
                      dispatch(setQuantity({id: product.id, qty: parseInt(product.quantity) + 1}))
                    }
                   
                  }} key="add"><AddIcon/></Button>
                </ButtonGroup>
          </CardActions> 
          <FormControl pb={2} sx={{ minWidth: "98%" }} size="small">
            <InputLabel id="demo-select-small">When do you want {product.name} ?</InputLabel>
            <Select
              onChange={(e)=>{
                dispatch(setMealType({id: product.id, meal: e.target.value}))
              
              }}
              labelId="demo-select-small"
              id="demo-select-small"
              label={`When do you want ${product.name} ?`}
            >
              <MenuItem selected value={product.meal_type}>
                {product.meal_type}
              </MenuItem>              
              <MenuItem value="Breakfast">Breakfast </MenuItem>
              <MenuItem value="Lunch">Lunch </MenuItem>
              <MenuItem value="Dinner">Dinner </MenuItem>
            </Select>
          </FormControl>
          
        </Card>
        
    </Grid>
      ))}
      </Grid>
    </Grid>

    <Grid item xs={12} md={4}>
        <Card>
        <Typography mt={2} sx={{ fontWeight: 'bold', fontSize: 18  }} gutterBottom variant="subtitle2" component="div">
            Order Summary
        </Typography>
        {contents.length > 0 &&
      <Table sx={{ minWidth: '100%',overflow: 'auto' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Product</TableCell>
          <TableCell align="left">Qty</TableCell>
          <TableCell align="left">Type</TableCell>
          <TableCell align="left">Unit Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contents.map((content) => (
          <TableRow
            key={content.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left">{content.name}</TableCell>
            <TableCell align="left">{content.quantity === undefined ? 1: content.quantity}</TableCell>
            <TableCell align="left">{content.meal_type}</TableCell>
            <TableCell align="left" >
            &#8358;{formatPrice(content.unit_price)}
            </TableCell>
          </TableRow>
        ))}

        <TableRow>
        <TableCell align="left" rowSpan={3} >
            <Typography sx={{ fontWeight: 'bold', fontSize: 18  }} gutterBottom variant="subtitle2" component="div">
            Total = &#8358;{formatPrice(total)}
            </Typography>          
        </TableCell>
        </TableRow>
      </TableBody>
    </Table>
      }
      <CardContent>
      {subscriptions.length < 1 && fundingBal < total && refBal < total &&
      <Stack sx={{ width: '100%' }} mb={2} spacing={2}>
          <Alert severity="warning">Your balance is too low, kindly fund your wallet to proceed</Alert>
          <WalletButton/>
      </Stack>
      }
      
      {(subscriptions.length > 0 || fundingBal >= total || refBal >= total) &&
      <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
          
      <InputLabel id="demo-select-small">Payment Source</InputLabel>

        
      {(error !== '' && error !== undefined && error.subscription_id) ?

        <Select
        error
        labelId="demo-select-small"
        id="demo-select-small"
        label="Payment Source"
        onChange={(e)=>setPaymentSource(e.target.value)}
        helperText={error.subscription_id}
        >
        <MenuItem value="">
          <em>Select Option</em>
        </MenuItem> 
        {fundingBal >= total && <MenuItem value="funding_balance">Funding Wallet    (Bal=&#8358;{formatPrice(fundingBal)}) </MenuItem>}             

        {refBal >= total && <MenuItem value="referral">Referral Wallet    (Bal=&#8358;{formatPrice(refBal)}) </MenuItem>}             

        {subscriptions.length > 0 && subscriptions.map(subscription =>(
          parseFloat(subscription.balance) > 0 && <MenuItem key={subscription.id} value={subscription.id}>{subscription.plan.name}    (Bal=&#8358;{formatPrice(subscription.balance)}) </MenuItem>
        ))}
        </Select>

      :
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        label="Payment Source"
        onChange={(e)=>setPaymentSource(e.target.value)}
      >
        <MenuItem value="">
          <em>Select Option</em>
        </MenuItem> 
        {fundingBal >= total && <MenuItem value="funding_balance">Funding Wallet    (Bal=&#8358;{formatPrice(fundingBal)}) </MenuItem>}             

        {refBal >= total && <MenuItem value="referral">Referral Wallet    (Bal=&#8358;{formatPrice(refBal)}) </MenuItem>}             
        
        {subscriptions.length > 0 && subscriptions.map(subscription =>(
          parseFloat(subscription.balance) > 0 && <MenuItem key={subscription.id} value={subscription.id}>{subscription.plan.name}    (Bal=&#8358;{formatPrice(subscription.balance)}) </MenuItem>
        ))}
      </Select>

      }
      
      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
      {error !== '' && 'Please select payment source'}
      </Typography>
    </FormControl>
      }
          

          <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
              <TextField
              minRows={2} multiline
              defaultValue={user.address}
              onChange={(e)=> setAddress(e.target.value)}
              id="address"
              name="address"
              type={'text'}
              label="Delivery Address"
              />
          </FormControl>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
              <InputLabel htmlFor="phone">Receiver Phone</InputLabel>
              <OutlinedInput
              onChange={(e)=> setPhone(e.target.value)}
              id="phone"
              defaultValue={user.phone}
              name="phone"
              type={'tel'}
              label="Receiver Phone"
              />              
          </FormControl>

          <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
              <TextField
              minRows={2} multiline
              onChange={(e)=> setExtraInfo(e.target.value)}
              placeholder='Let us know something else about your order, maybe time to deliver or fruits to remove from your fruit salad, anyway we can make your order special we will be glad to do it.'
              id="info"
              name="info"
              type={'text'}
              label="Extra Information (Optional)"
              />
          </FormControl>

          <Box>
            <Stack ml={1} sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">{promoMessage}</Alert>
            </Stack>
          </Box>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
              <InputLabel htmlFor="promo">Promo Code</InputLabel>
              <OutlinedInput
              onChange={(e)=> calculatePromo(e.target.value)}
              id="promo"
              defaultValue={user.phone}
              name="promo"
              type={'text'}
              label="Promo Code"
              />              
          </FormControl>

      </CardContent>

      <CardActions>
      <Button onClick={()=>{
       const event3 = new Event('processing');
        window.dispatchEvent(event3);
        storeOrder();
       }} fullWidth={true} size="large" color="primary" variant="contained"> {isOrdering? 'Sending...' : 'Checkout'}</Button>
      </CardActions>
        </Card>
    </Grid>
    </Grid>
    }
    </div>
  )
}

export default PlateContent
