import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptions: JSON.parse(localStorage.getItem('subscriptions')) || [],
}

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setsubscriptions: (state, action) =>{
            state.subscriptions = action.payload;  
            localStorage.setItem('subscriptions', JSON.stringify(state.subscriptions));
        }       
    }
})

export const {setsubscriptions} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;