import React from 'react'
import PackageSlides from './PackageSlides';
import MobileMenu from './MobileMenu';
import TodayBreakFast from './TodayBreakFast';
import TodayLunch from './TodayLunch';
import TodayDinner from './TodayDinner';
import FoodPlans from '../Subscriptions/FoodPlans';

const Index = () => {
  return (
    <>
    <PackageSlides/>
    <MobileMenu/>
    <TodayBreakFast/>
    <TodayLunch/>
    <TodayDinner/>
    <FoodPlans/>
    </>
  )
}

export default Index
