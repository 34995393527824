import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setsubscriptions } from '../../components/Subscriptions/subscriptionSlice';

export const LoadSubscriptions = () => {
    const dispatch = useDispatch();
    const { isLoading: isLoadingSubscriptions, refetch: getSubscriptions } = useQuery(
        "subscriptions",
        async () => {
          return await apiClient.get("/api/view-subscriptions");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setsubscriptions(res.data.subscriptions));
                const event = new Event('updatedStorage');
                window.dispatchEvent(event);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getSubscriptions();
      }, []);
 
}

export default LoadSubscriptions
