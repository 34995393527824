import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setNotifications } from '../../components/Notifications/notificationSlice';

export const FetchNotifications = () => {
  const dispatch = useDispatch();

    const { isLoading: isLoadingFundings, refetch: getNotifications } = useQuery(
        "fetch-notifications",
        async () => {
          return await apiClient.get("/api/fetch-notifications");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setNotifications(res.data.notifications))
                const event = new Event('updatedStorage');
                window.dispatchEvent(event);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getNotifications();
      }, []);
 
}

export default FetchNotifications
