import React, {useEffect, useState} from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import Wave from 'react-wavify';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ValidateAmount from './ValidateAmount';

import { useMutation } from "react-query";
import apiClient from "../../request/http-common";
import SubscriptionList from './SubscriptionList';
import { useDispatch } from 'react-redux';
import { setsubscriptions } from './subscriptionSlice';
import { useSelector } from 'react-redux';

const SubscriptionPlans = () => {
    const dispatch = useDispatch();
    const plans = useSelector((state) => state.plans.plans);
    const formatPrice = (amount) => new Intl.NumberFormat().format(amount);
    const [planId, setPlanId] = useState('');

    const { isLoading: isSubscribing, mutate: storeSubscription } = useMutation(
        async () => {
          if (planId !== '') {
            return await apiClient.post(`/api/store-subscription`, {
              plan_id: planId,
              type: 'plan'
            });
          }
          
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {

                dispatch(setsubscriptions(res.data.subscriptions));
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
              }            
          },
          onError: (err) => {
            let errorMessage = err.response?.data || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);

            const event4 = new Event('processed');
            window.dispatchEvent(event4);
            
          },
        }
      );

  return (
    <div style={{marginTop: '80px', marginBottom: '20px'}}>
        <Container>
    <Typography ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 28, textAlign: 'center'  }} gutterBottom variant="h3" component="div">
        Food Subscription Plans
    </Typography>
    <Typography my={0} sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
        Minimize cost of feeding and save yourself the stress of cooking with our meal subscription plans
    </Typography>
    {/* <ValidateAmount/> */}
    {plans.length > 0 &&
        <Grid container mt={4} mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {plans.map(plan =>(
                <Grid key={plan.id} item xs={12} md={3}>
                <Card sx={{marginX: '5px', borderRadius: '9px'}}>
                    <CardMedia
                        component="div"
                        sx={{ width: '100%' }}>
                     <Wave fill='#0cab5c'
                                paused={false}
                                options={{
                                height: 20,
                                amplitude: 30,
                                speed: 0.2,
                                points: 3
                              }}
                        />
                    <Typography mb={2}  sx={{ color: '#fff', fontWeight: 'bold', fontSize: 20, transform: 'translate(0px, -100px)'  }} gutterBottom variant="subtitle2" component="div">
                    {plan.name}
                    </Typography>

                    <Typography mb={-6}  sx={{ color: '#fff', fontWeight: 'bold', fontSize: 20, transform: 'translate(0px, -100px)'  }} gutterBottom variant="subtitle2" component="div">
                    &#8358;{formatPrice(plan.amount)}/Month
                    </Typography>
                    
                    </CardMedia>
                    
                    <List >
                        <ListItem>
                        <ListItemIcon>
                            <CheckCircleIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${plan.num_meals_per_day} Square Meal`}
                            // secondary={'Secondary text'}
                        />
                        </ListItem>
    
    
                        <ListItem>
                        <ListItemIcon>
                            <CheckCircleIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText
                            primary={`₦${formatPrice(plan.max_amount)} or less per meal`}
                            // secondary={'Secondary text'}
                        />
                        </ListItem>
    
                        <ListItem>
                        <ListItemIcon>
                            <CheckCircleIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText
                            primary="Free Delivery"
                            // secondary={'Secondary text'}
                        />
                        </ListItem>
                    </List>
    
                    <CardActions sx={{justifyContent: 'center'}}>
                    <Button onClick={()=>
                    {setPlanId(plan.id);
                      const event3 = new Event('processing');
                      window.dispatchEvent(event3);
                      setTimeout(() => {
                      storeSubscription();
                      
                    }, 2000); }}
                    
                    sx={{ textTransform: 'capitalize' }} variant="contained" startIcon={<CardMembershipIcon />}>
                     {isSubscribing? planId === plan.id ? 'Subscribing...' : 'Subscribe': 'Subscribe'} 
                    </Button>
                    </CardActions>    
    
                </Card>
            </Grid>
            ))}
    </Grid>
    }
    <SubscriptionList/>
    </Container>

    
</div>
  )
}

export default SubscriptionPlans
