import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: JSON.parse(localStorage.getItem('authstatus')) || false,
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAuthStatus: (state, action) =>{
             
            if (action.payload === 'authenticated') {
                state.isAuthenticated = true;
                localStorage.setItem('authstatus', true);
            }else{
                state.isAuthenticated = false;
                localStorage.setItem('authstatus', false);
                localStorage.removeItem('user');
                localStorage.removeItem('fundings');
                localStorage.setItem('funding-balance', 0);
                localStorage.setItem('subscriptionBalance', 0);
                localStorage.removeItem('subscriptions');
                localStorage.removeItem('notifications');
            } 
            
        }       
    }
})

export const {setAuthStatus} = authSlice.actions;
export default authSlice.reducer;