import * as React from 'react';
import { Fragment, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import apiClient from "../../request/http-common";
import { useMutation } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setNotifications } from './notificationSlice';
import { useNavigate } from 'react-router-dom';

export default function ListNotifications() {
const dispatch = useDispatch();
const navigate = useNavigate();
const notifications = useSelector((state) => state.notifications.notifications);
const [notificationId, setNotificationId] = useState('');

const { isLoading: isReading, mutate: readNotification } = useMutation(
    async () => {
      return await apiClient.post(`/api/read-notifications`, {
        id: notificationId
      });
    },
    {
      onSuccess: (res) => {
        dispatch(setNotifications(res.data.notifications))
      },
      onError: (err) => {   
        //    
      },
    }
  );
  return (
    <div style={{marginTop: '70px'}}>

    <Typography  mt={4} mb={-2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        NOTIFICATIONS
    </Typography>

    <Grid container px={2}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={6}>
        {notifications.length > 0 &&
        notifications.map((notification, index) =>(
            <List
             onClick={()=>
             {setNotificationId(notification.id);
                setTimeout(() => {
                    readNotification();
                }, 1000);
                setTimeout(() => {
                    navigate(notification.data.url)
                }, 2000);
                
             }} key={index} sx={{ width: '100%', cursor: 'pointer', maxWidth: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemText
                    primary={notification.data.title}
                    secondary={notification.data.content}
                    />
                </ListItem>
                <Divider  component="li"/>
            </List>
            
        ))
}
    </Grid>
    </Grid>
    {notifications.length < 1 &&
        <Box mx={2} mt={10}>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">You currently do not have any notifications yet</Alert>
            </Stack>
        </Box>
    }
    </div>
  );
}