import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    referrals: JSON.parse(localStorage.getItem('referrals')) || [],
    inviter: localStorage.getItem('inviter') || ''

}

export const referralSlice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {
        setReferrals: (state, action) =>{
            state.referrals = action.payload;  
            localStorage.setItem('referrals', JSON.stringify(state.referrals));
        },

        setInviter: (state, action) =>{
            state.inviter = action.payload;  
            localStorage.setItem('inviter', state.inviter);
        } 

    }
})

export const {setReferrals, setInviter} = referralSlice.actions;
export default referralSlice.reducer;