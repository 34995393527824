import React, {useState, useEffect} from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Plate from '../Plates/Plate';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setProduct } from '../Products/productSlice';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

const TodayDinner = () => {
    const scrolltoTop = () => window.scrollTo(0, 0);
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.dinners);   

  return (
    <div>
    <Typography mb={-5} ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'left', textTransform: 'capitalize'  }} gutterBottom variant="h6" component="div">
        Today's Menu For Dinner
    </Typography>
    {products.length > 0 &&
    <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {products.map(product=>(
        <Grid key={product.id} item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
        <Link onClick={()=> {dispatch(setProduct(product.product)); scrolltoTop()}}  to={`/product`} component={RouterLink} underline="none" color="inherit">
            <CardMedia
                component="img"
                sx={{ width: '100%' }}
                image={product.product.product_image}
                alt={product.product.description}
            />
            <CardContent >
            <Box mt={-1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
              <Stack mt={-1} sx={{ justifyContent: 'center', alignItems: 'center' }}  spacing={1}>
                <Rating name="half-rating" size="small" defaultValue={4.5} precision={0.5} readOnly />
              </Stack>
              <Typography ml={1} sx={{ fontWeight: 'normal', fontSize: 16, textTransform: 'capitalize' }} gutterBottom variant="subtitle2" component="span">
                4.5
              </Typography>
            </Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16  }} gutterBottom variant="subtitle2" component="div">
                {product.product.name}
            </Typography>
            <Typography my={0} variant="body2" color="text.secondary">
                {product.product.short_description}
            </Typography>
            
            </CardContent>
            <Typography color="secondary" sx={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1   }} gutterBottom variant="button" component="span">
            &#8358;{product.product.unit_price}
            </Typography>
          </Link>
            <CardActions sx={{justifyContent: 'center'}}>
            <Plate product={product.product} />
            </CardActions>    
        </Card>
    </Grid>
      ))}
</Grid>
    }
</div>
  )
}

export default TodayDinner
