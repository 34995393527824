import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import WineBarIcon from '@mui/icons-material/WineBar';
import AppleIcon from '@mui/icons-material/Apple';
import CookieIcon from '@mui/icons-material/Cookie';
import TapasIcon from '@mui/icons-material/Tapas';
import KebabDiningIcon from '@mui/icons-material/KebabDining';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import Badge from '@mui/material/Badge';
import DiningIcon from '@mui/icons-material/Dining';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

export default function Offline() {

  const items = useSelector((state) => state.plate.items);
  return (
        <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/today-breakfast' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <FreeBreakfastIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Breakfast`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Link  to='/today-lunch' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <LunchDiningIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Lunch`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Link  to='/today-dinner' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <DinnerDiningIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Dinner`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/plate-content' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Badge badgeContent={items.length} color="error">
                      <DiningIcon />
                  </Badge>
                  </ListItemIcon>
                  <ListItemText  primary={`Plate`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            
          </List>

          <Divider />
          <List>
          <Link  to='/subscriptions' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CardMembershipIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Subscription`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            

            <Link  to='/login' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <LoginIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Login`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Divider />

            <Link  to='/products' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <RestaurantMenuIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`All Foods`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Assorted' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <SoupKitchenIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Assorted`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Proteins' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <KebabDiningIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Proteins`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Drinks' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <WineBarIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Drinks`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Fruits' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AppleIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Fruits`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Desserts' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CookieIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Desserts`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Appetizers' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <TapasIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Appetizers`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            

            <Link  to='/category/Fruits' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AppleIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Fruits`} />
                  </ListItemButton>
              </ListItem>
            </Link>
            <Divider/>
            <Link  to='https://tawk.to/chat/642872214247f20fefe94604/1gsuvrltc'  component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <HelpCenterIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Live Support`} />
                </ListItemButton>
            </ListItem>
          </Link>
          </List>
    </Fragment>
  );
}