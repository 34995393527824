import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';


export default function ListSubscriptions() {
    const [subscriptions, setSubscriptions] = useState(null);
    const { isLoading: isLoadingsubscriptions, refetch: getSubscriptions } = useQuery(
        "admin-subscriptions",
        async () => {
          return await apiClient.get("/api/admin-subscriptions");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setSubscriptions(res.data.subscriptions);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getSubscriptions() 
        if (isLoadingsubscriptions) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        List of subscriptions
      </Typography>
    <TableContainer component={Paper}>
        
      {subscriptions !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        
          <TableCell align="left">Customer Name</TableCell>
          <TableCell align="left">Customer Email</TableCell>
          <TableCell align="left">Plan Name</TableCell>
          <TableCell align="left">Amount</TableCell>
          <TableCell align="left">Balance</TableCell>
          <TableCell align="left">Status</TableCell>
          <TableCell align="left">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subscriptions.map((subscription) => (
          <TableRow
            key={subscription.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left">{subscription.user.name}</TableCell>
            <TableCell align="left">{subscription.user.email}</TableCell>
            <TableCell align="left">{subscription.plan.name}</TableCell>
            <TableCell align="left">{subscription.amount}</TableCell>
            <TableCell align="left">{subscription.balance}</TableCell>
            <TableCell align="left">{subscription.status}</TableCell>
            <TableCell align="left">{getDate(subscription.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}