import * as React from 'react';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';


export default function MobileMenu() {
  return (
    <Stack mt={2} sx={{display: {md: 'none'}}} direction="row" spacing={5} justifyContent="center" alignItems="center">
      <Box component={RouterLink}  to='/products' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1676936704/platemanu.png" sx={{ padding: '5px', bgcolor: green[50], width: 56, height: 56}}>
        {/* <PageviewIcon /> */}
      </Avatar>  
      <span>Food</span>   
      </Box>

      <Box component={RouterLink} to='/category/Snacks' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar src='https://res.cloudinary.com/dzxyvdq14/image/upload/v1676937038/burgarmenu340.png' sx={{ padding: '5px', bgcolor: green[50], width: 56, height: 56}}>
        {/* <AssignmentIcon /> */}
      </Avatar>
      <span>Snacks</span> 
      </Box>

      <Box component={RouterLink} to='/category/Drinks' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit'  }}>
      <Avatar src='https://res.cloudinary.com/dzxyvdq14/image/upload/v1676937141/foodagain340.png' sx={{ padding: '5px', bgcolor: green[50], width: 56, height: 56}}>
        {/* <AssignmentIcon /> */}
      </Avatar>
      <Typography sx={{textAlign: 'center'}}>Drinks</Typography> 
      </Box>

      <Box component={RouterLink} to='/category/Salad' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar src='https://res.cloudinary.com/dzxyvdq14/image/upload/v1676937370/saladmanu350.png' sx={{ padding: '5px', bgcolor: green[50], width: 56, height: 56}}>
        {/* <AssignmentIcon /> */}
      </Avatar>
      <span>Salad</span> 
      </Box>      
    </Stack>
  );
}