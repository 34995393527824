import React, {useEffect, useState} from 'react';

const Timer = (props) => {
    const {time} = props;

    const countDownDate = new Date(time * 1000);
    const [text, setText] = useState('');

    useEffect(() => {
        var timer = setInterval(function() {
            var now = new Date().getTime();
            var distance = countDownDate - now;
          
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    
            if (distance < 0) {
              clearInterval(timer);
              setText('Almost Ready')
            }else{
                setText(`${hours}h ${minutes}m ${seconds}s`)
            }
          }, 1000);

        return () => {
            clearInterval(timer)
        };
    }, [time]);

    // Update the count down every 1 second
    

  return (
    <div>
        {text}
    </div>
  )
}

export default Timer
