import React, {useState, useEffect} from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Slider from "react-slick";
import { Link as RouterLink } from 'react-router-dom';
import Wave from 'react-wavify';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { Button, CardActions } from '@mui/material';
import { useSelector } from 'react-redux';


const formatPrice = (amount) => new Intl.NumberFormat().format(amount);
const FoodPlans = () => {
const plans = useSelector((state) => state.plans.plans);
  
    const settings = {
        autoplay: true,
        pauseOnHover: true,
        autoplaySpeed: 5000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (
    <div>
    <Typography mb={-8} mt={4} px={3} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'left'  }} gutterBottom variant="h6" component="div">
        Our Subscription Plans
    </Typography>
    <Typography mb={-8}  mt={8} px={3} sx={{  textAlign: 'left', fontSize: 14  }} variant="subtitle1" gutterBottom>
        Let's take away cooking stress from you and reduce cost with our subscription plans
      </Typography>
      {plans.length > 0 &&
      <Card sx={{marginTop: '65px', paddingX: '10px', marginBottom: '0px'}} elevation={0}>
      <Slider {...settings}>
        {plans.map(plan =>(
          <div key={plan.id}>
            <Card sx={{marginX: '10px', borderRadius: '9px'}}>
            <CardMedia
                component="div"
                sx={{ width: '100%' }}>
             <Wave fill='#0cab5c'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 30,
                        speed: 0.2,
                        points: 3
                      }}
                />
              <Typography mb={2}  sx={{ color: '#fff', fontWeight: 'bold', fontSize: 20, transform: 'translate(0px, -100px)'  }} gutterBottom variant="subtitle2" component="div">
            {plan.name}
            </Typography>
            <Typography mb={-6}  sx={{ color: '#fff', fontWeight: 'bold', fontSize: 20, transform: 'translate(0px, -100px)'  }} gutterBottom variant="subtitle2" component="div">
            &#8358;{formatPrice(plan.amount)}/Month
            </Typography>
            </CardMedia>
            
            <List >
                <ListItem>
                <ListItemIcon>
                    <CheckCircleIcon color='primary' />
                </ListItemIcon>
                <ListItemText
                    primary={`${plan.num_meals_per_day} Square Meal`}
                    // secondary={'Secondary text'}
                />
                </ListItem>


                <ListItem>
                <ListItemIcon>
                    <CheckCircleIcon color='primary' />
                </ListItemIcon>
                <ListItemText
                    primary={`₦${formatPrice(plan.max_amount)} or less per meal`}
                    // secondary={'Secondary text'}
                />
                </ListItem>

                <ListItem>
                <ListItemIcon>
                    <CheckCircleIcon color='primary' />
                </ListItemIcon>
                <ListItemText
                    primary="Free Delivery"
                    // secondary={'Secondary text'}
                />
                </ListItem>
            </List>

            <CardActions sx={{justifyContent: 'center'}}>
            <Button component={RouterLink} to="/subscriptions" sx={{ textTransform: 'capitalize' }} variant="contained">
              get started
            </Button>
            </CardActions>    

        </Card>
        </div>
        ))}
      </Slider>
  </Card>
      }
    
</div>
  )
}

export default FoodPlans
