import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CreateMessage = () => {
           
    const initialCategory = {
        title: '',
        message: '',
        media_url: '',
        media_type: '',
        link_text: '',
        external_link: ''
    }
    
    const [error, setErros] = useState('');
    const [categoryForm, setcategoryForm] = useState(initialCategory);

    const onChange = (e) =>
    setcategoryForm({ ...categoryForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postCategory } = useMutation(

        async () => {
          return await apiClient.post(`/api/store-message`, {
            title: categoryForm.title,
            message: categoryForm.message,
            media_url: categoryForm.media_url,
            media_type: categoryForm.media_type,
            external_link: categoryForm.external_link,
            link_text: categoryForm.link_text
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postCategory();
      } 


  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        CREATE UPDATE MESSAGE
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create message
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Message Title">Message Title</InputLabel>
                            {(error !== '' && error.title) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="title"
                            name="title"
                            type={'text'}
                            label="Message Title"
                            helperText={error.title}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="title"
                            name="title"
                            type={'text'}
                            label="Message Title"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.title}
                            </Typography>
                        </FormControl>
                        
                        
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="message">Message</InputLabel>
                            {(error !== '' && error.message) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="message"
                            name="message"
                            type={'text'}
                            label="message"
                            helperText={error.message}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="message"
                            name="message"
                            type={'text'}
                            label="message"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.message}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="media_url">Media URL</InputLabel>
                            {(error !== '' && error.media_url) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="media_url"
                            name="media_url"
                            type={'text'}
                            label="Media URL"
                            helperText={error.media_url}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="media_url"
                            name="media_url"
                            type={'text'}
                            label="Media URL"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.media_url}
                            </Typography>
                        </FormControl>

                        
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="media_type">Media Type</InputLabel>
                            {(error !== '' && error.media_type) ?
                            <Select
                            error
                            onChange={onChange}
                            labelId="media_type"
                            id="media_type"
                            name="media_type"
                            label={`Media Type`}
                            helperText={error.media_type}
                            >
                            <MenuItem selected value=''>
                                Media Type
                            </MenuItem>              
                            <MenuItem value="image">Image </MenuItem>
                            <MenuItem value="video">Video </MenuItem>
                        </Select>
                            :
                            <Select
                                onChange={onChange}
                                labelId="media_type"
                                name="media_type"
                                id="media_type"
                                label={`Media Type`}
                                >
                                <MenuItem selected value=''>
                                    Media Type
                                </MenuItem>              
                                <MenuItem value="image">Image </MenuItem>
                                <MenuItem value="video">Video </MenuItem>
                            </Select>
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.media_type}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="external_link">External Link</InputLabel>
                            {(error !== '' && error.external_link) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="external_link"
                            name="external_link"
                            type={'text'}
                            label="external_link"
                            helperText={error.external_link}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="external_link"
                            name="external_link"
                            type={'text'}
                            label="external_link"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.external_link}
                            </Typography>
                        </FormControl>



                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="link_text">Link Text</InputLabel>
                            {(error !== '' && error.link_text) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="link_text"
                            name="link_text"
                            type={'text'}
                            label="link_text"
                            helperText={error.link_text}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="link_text"
                            name="link_text"
                            type={'text'}
                            label="link_text"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.link_text}
                            </Typography>
                        </FormControl>

                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Creating...' : 'Create category'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreateMessage
