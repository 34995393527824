import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { CardActions } from '@mui/material';
import Plate from '../Plates/Plate';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setProduct } from '../Products/productSlice';
import { useSelector } from 'react-redux';
import Slider from "react-slick";

export default function TodayLunch() {
  const scrolltoTop = () => window.scrollTo(0, 0);
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.lunches);

    const settings = {
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 3000,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

  return (
    <div>
    <Typography mb={-7} ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'left', textTransform: 'capitalize'  }} gutterBottom variant="h6" component="div">
      Today's Menu For Lunch
  </Typography>
    <Card sx={{marginTop: '65px', paddingX: '10px', marginBottom: '50px'}} elevation={0}>
      {products.length > 0 &&
        <Slider {...settings}>
          {products.map(product =>(
              <div  key={product.id}>
              <Card sx={{ display: 'flex',  justifyContent: 'space-between', marginX: '5px', borderRadius: '10px', marginBottom: '10px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Link onClick={()=> {dispatch(setProduct(product.product)); scrolltoTop()}}  to={`/product`} component={RouterLink} underline="none" color="inherit">
                  <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 18, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                      {product.product.name}
                  </Typography>
                  <Typography color="secondary" sx={{ fontWeight: 'bold', fontSize: 16   }} gutterBottom variant="button" component="div">
                          &#8358;500.00
                  </Typography>
                  <Typography my={0} variant="body2" color="text.secondary">
                      {product.product.short_description} 
                  </Typography>
                  </CardContent>
                    
                  <Stack sx={{ transform: 'translate(0px, -15px)', justifyContent: 'center', alignItems: 'center' }}  spacing={1}>
                    <Rating name="half-rating" size="small" defaultValue={4.5} precision={0.5} readOnly />
                  </Stack>   
                  </Link>         
                </Box>
                <Box>
                <Link onClick={()=> {dispatch(setProduct(product.product)); scrolltoTop()}}  to={`/product`} component={RouterLink} underline="none" color="inherit">
                  <CardMedia
                    component="img"
                    sx={{ height: 120, maxWidth: 150 }}
                    image={product.product.product_image}
                    alt="food"
                  />
                  </Link>
                  <CardActions sx={{justifyContent: 'center'}}>
                  <Plate product={product.product} />
                  </CardActions> 
                </Box>
              </Card>
              </div>
          ))}
      </Slider>
      }
      
    </Card>
</div>
  );
}