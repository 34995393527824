import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useMutation } from "react-query";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import apiClient from '../../request/http-common';
import { setUser } from '../Auth/userSlice';
import { useDispatch } from 'react-redux';

export default function EditProfile(props) {
    const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
    const { address, phone} = props;
    const [error, setErros] = useState('');

    const initialLogin = {
        address: address,
        phone: phone,

    }
    const [registerForm, setRegisterForm] = useState(initialLogin);
    const onChange = (e) =>
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(

        async () => {
          return await apiClient.post(`/api/update-contact`, {
            address: registerForm.address,
            phone: registerForm.phone,

          });
        },
        {
          onSuccess: (res) => {
            dispatch(setUser(res.data.user));
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            const event = new Event('newMessage');
            window.dispatchEvent(event);
           
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.message) 
            const event2 = new Event('processed');
            window.dispatchEvent(event2);      
          },
        }
      );

  return (
    <React.Fragment>
      <Button color="secondary" variant="contained" size='large' onClick={handleClickOpen}>
        EDIT CONTACT DETAILS
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Change Delivery Address</DialogTitle>
        <DialogContent>
          <DialogContentText mm={4}>
            Moved to a new address or want to change phone number ?<br/>
            Use the form below to update account details.
          </DialogContentText>
          
          <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="address">Address</InputLabel>
            {(error !== '' && error.address) ?
            <OutlinedInput
            error
            helperText={error.address}
            onChange={onChange}
            defaultValue={address}
            minRows={2} multiline
            id="address"
            name="address"
            type={'text'}
            label="Address"
            />
            :
            <OutlinedInput
            onChange={onChange}
            minRows={2} multiline
            defaultValue={address}

            id="address"
            name="address"
            type={'text'}
            label="Address"
            />
            }
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
            {error !== '' && error.address}
            </Typography>
        </FormControl>

        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="phone">Phone</InputLabel>
            {(error !== '' && error.phone) ? 
            <OutlinedInput
            error
            onChange={onChange}
            defaultValue={phone}

            id="phone"
            name="phone"
            type={'tel'}
            label="Phone"
            helperText={error.phone}
            />
            :
            <OutlinedInput
            onChange={onChange}
            id="phone"
            defaultValue={phone}

            name="phone"
            type={'tel'}
            label="Phone"
            />
            }
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
            {error !== '' && error.phone}
            </Typography>
        </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <Button onClick={postLogin}> {isSendingRequest ? 'Updating details...' : 'Submit Changes'}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}