import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messages: JSON.parse(localStorage.getItem('messages')) || '',
    readMessages: localStorage.getItem('readMessage') || '',
}

export const messagestSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setMessages: (state, action) =>{
            state.messages = action.payload;  
            localStorage.setItem('messages', JSON.stringify(state.messages));
        }, 
        
        setReadMessages: (state, action) => {
            state.readMessages = action.payload.id;
            localStorage.setItem('readMessage', state.readMessages);
            console.log(state.readMessages);
        }
    }
})

export const {setMessages, setReadMessages} = messagestSlice.actions;
export default messagestSlice.reducer;