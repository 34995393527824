import * as React from 'react';
import { useEffect, useState, Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';


import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useMutation } from "react-query";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function UserPlates(props) {
    const {plates} = props;
    const [open, setOpen] = useState(0);
      
      const [open2, setOpen2] = React.useState(false);
      const handleClose2 = () => {
        setOpen2(false);
      };
      const handleToggle2 = () => {
        setOpen2(!open2);
      };

      const [open3, setOpen3] = React.useState(false);
      const handleClose3 = () => {
        setOpen3(false);
      };
      const handleToggle3 = () => {
        setOpen3(!open3);
      };


    const [orderStatus, setOrderStatus] = useState('');
    const [plateID, setPlateId] = useState('');
    const [productId, setProductId] = useState('');

      const { isLoading: isUpdating, mutate: updateOrder } = useMutation(
        async () => {
          return await apiClient.post(`/api/update-plate`, {
            plate_id: plateID,
            product_id: productId,
            status: orderStatus,
          });
          
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
      
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                setTimeout(() => {
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
                }, 1000);
      
                const event2 = new Event('processed');
                window.dispatchEvent(event2);                
                
              }            
          },
          onError: (err) => {
            let errorMessage = err.response?.data || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event4 = new Event('processed');
            window.dispatchEvent(event4);
            
          },
        }
      );

      const { isLoading: isUpdatingMainOrder, mutate: updateMainOrder } = useMutation(
        async () => {
          return await apiClient.post(`/api/update-main-order`, {
            plate_id: plateID,
            status: orderStatus,
          });
          
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
      
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                setTimeout(() => {
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
                }, 1000);
      
                const event2 = new Event('processed');
                window.dispatchEvent(event2);                
                
              }            
          },
          onError: (err) => {
            let errorMessage = err.response?.data || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event4 = new Event('processed');
            window.dispatchEvent(event4);
            
          },
        }
      );

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px', marginBottom: '20px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        List of Orders
      </Typography>
    <TableContainer component={Paper}>
        
      {plates !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        <TableCell/>
            <TableCell>Phone</TableCell>
            <TableCell align="right">Amount Paid</TableCell>
            <TableCell align="right">Delivery Address</TableCell>
            <TableCell align="right">Extra Information</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Profile</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Manage</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>

        {plates.map((plate) => (
        <Fragment key={plate.id}>
          <TableRow
            key={plate.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          ><TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {open === plate.id ? setOpen(0): setOpen(plate.id) } }
          >
            {open === plate.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
            <TableCell align="right">{plate.phone}</TableCell>
            <TableCell align="right">{plate.amount}</TableCell>
            <TableCell align="right">{plate.address}</TableCell>
            <TableCell align="right">{plate.other_details}</TableCell>
            <TableCell align="right">{plate.status}</TableCell>
            <TableCell align="right"><Avatar alt={plate.user.name} src={plate.user.profile} /></TableCell>
            <TableCell align="right">{getDate(plate.created_at)}</TableCell>
            <TableCell align="right">
                <Button  
                    onClick={() =>{handleToggle3(); setPlateId(plate.id);}}                   
                    sx={{ textTransform: 'capitalize' }} variant="contained" startIcon={<EditIcon />}>
                    Edit
                </Button>
            </TableCell>
            
          </TableRow>


          <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open === plate.id} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6"  gutterBottom component="div">
                Order Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Product Name</TableCell>
                    <TableCell align="left">Meal</TableCell>
                    <TableCell align="left">Unit Price</TableCell>
                    <TableCell align="left">Quantity</TableCell>
                    <TableCell align="left">Product Image</TableCell>
                    <TableCell align="left">Manage</TableCell>
                    <TableCell align="left">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {JSON.parse(plate.content).map((product) => (
                    <TableRow key={product.id}>
                      <TableCell align="left" component="th" scope="row">{product.name}</TableCell>
                      <TableCell align="left">{product.meal_type}</TableCell>
                      <TableCell align="left">{product.unit_price}</TableCell>
                      <TableCell align="left">{product.quantity ? product.quantity: 1}</TableCell>
                      <TableCell align="left"><Avatar alt={product.name} src={product.product_image} variant="square"/></TableCell>
                      <TableCell align="left">
                    <Button  
                        onClick={() =>{handleToggle2(); setPlateId(plate.id); setProductId(product.id)}}                   
                        sx={{ textTransform: 'capitalize' }} variant="contained" startIcon={<EditIcon />}>
                      Edit
                    </Button>
                    </TableCell>
                    <TableCell align="right">{product.status === undefined ? plate.status : product.status}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>

    <Dialog
        
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"UPDATE STATUS"}</DialogTitle>
        <DialogContent>
          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={(e) => setOrderStatus(e.target.value)}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="confirmed">Confirmed</MenuItem>
                    <MenuItem value="on the way">on the way</MenuItem>
                    <MenuItem value="delivered">delivered</MenuItem>
                    <MenuItem value="declined">declined</MenuItem>
                </Select>
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
            <Button onClick={()=>updateOrder()} color='error'>{isUpdating? 'Updating...': 'Update Status'}</Button>
        </DialogActions>
      </Dialog>



      <Dialog
        
        open={open3}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose3}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"UPDATE STATUS"}</DialogTitle>
        <DialogContent>
          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={(e) => setOrderStatus(e.target.value)}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="confirmed">Confirmed</MenuItem>
                    <MenuItem value="on the way">on the way</MenuItem>
                    <MenuItem value="delivered">delivered</MenuItem>
                    <MenuItem value="declined">declined</MenuItem>
                </Select>
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
            <Button onClick={()=>updateMainOrder()} color='error'>{isUpdatingMainOrder? 'Updating...': 'Update Status'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}