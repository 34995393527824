import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setMessages } from '../../components/Message/messageSlice';

export const FetchMessages = () => {

  const dispatch = useDispatch();

    const { isLoading: isLoadingFundings, refetch: getNotifications } = useQuery(
        "fetch-messages",
        async () => {
          return await apiClient.get("/api/fetch-messages");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setMessages(res.data.content))
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getNotifications();
      }, []);
 
}

export default FetchMessages
