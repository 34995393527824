import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    featureds: JSON.parse(localStorage.getItem('featureds')) || [],
}

export const featuredSlice = createSlice({
    name: 'featured',
    initialState,
    reducers: {
        setFeatured: (state, action) =>{
            state.featureds = action.payload;  
            localStorage.setItem('featureds', JSON.stringify(state.featureds));
        }       
    }
})

export const {setFeatured} = featuredSlice.actions;
export default featuredSlice.reducer;