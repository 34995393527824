import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useMutation } from "react-query";
import { setAuthStatus } from '../../request/Auth/authSlice';

import apiClient from '../../request/http-common';
import { setUser } from '../Auth/userSlice';
import { useDispatch } from 'react-redux';

export default function DeleteAccount(props) {
    const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
    const [error, setErros] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(

        async () => {
          return await apiClient.post(`/api/delete-account`);
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            const event = new Event('newMessage');
            window.dispatchEvent(event);

            dispatch(setAuthStatus('unauthenticated'));
            localStorage.removeItem('user');
            localStorage.removeItem('fundings');
            localStorage.setItem('funding-balance', 0);
            localStorage.setItem('subscriptionBalance', 0);
            localStorage.removeItem('subscriptions');
            localStorage.removeItem('notifications');
            window.location.href = '/';
           
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.message) 
            const event2 = new Event('processed');
            window.dispatchEvent(event2);      
          },
        }
      );

  return (
    <React.Fragment>
      <Button color="error" variant="contained" size='large' onClick={handleClickOpen}>
        DELETE ACCOUNT
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Delete Account</DialogTitle>

        <DialogContent>
          <DialogContentText mm={4}>
            Are you sure you want to delete your account ?
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{justifyContent: 'space-between'}}>
          <Button color='info' onClick={handleClose}>Cancel</Button>
          <Button color='error' onClick={postLogin}> {isSendingRequest ? 'deleting details...' : 'delete account'}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}