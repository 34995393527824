import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: JSON.parse(localStorage.getItem('products')) || [],
    breakfasts: JSON.parse(localStorage.getItem('breakfasts')) || [],
    lunches: JSON.parse(localStorage.getItem('lunches')) || [],
    dinners: JSON.parse(localStorage.getItem('dinners')) || [],
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts: (state, action) =>{
            state.products = action.payload;  
            localStorage.setItem('products', JSON.stringify(state.products));
        },
        
        setBreakfasts: (state, action) =>{
            state.breakfasts = action.payload;  
            localStorage.setItem('breakfasts', JSON.stringify(state.breakfasts));
        },

        setLunches: (state, action) =>{
            state.lunches = action.payload;  
            localStorage.setItem('lunches', JSON.stringify(state.lunches));
        },

        setDinners: (state, action) =>{
            state.dinners = action.payload;  
            localStorage.setItem('dinners', JSON.stringify(state.dinners));
        } 
    }
})

export const {setProducts, setBreakfasts, setLunches, setDinners } = productsSlice.actions;
export default productsSlice.reducer;