import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


export default function SubscriptionList() {

  const getDate = (date) => new Date(date).toDateString();
  const formatPrice = (amount) => new Intl.NumberFormat().format(amount);

  const [subscriptions, setsubScriptions] = useState([]);
  useEffect(() => {
    const subList = JSON.parse(localStorage.getItem('subscriptions'));
    if (subList) {
      setsubScriptions(subList);
    }
    window.addEventListener('updatedStorage', function (e) {
      const subList = JSON.parse(localStorage.getItem('subscriptions'));
      if (subList) {
        setsubScriptions(subList);
      }
  }, false);  

  }, []);

  return (
    <div>
     <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'left'  }} gutterBottom variant="h6" component="div">
       {subscriptions.length > 0 && 'Your Subscription History'} 
    </Typography>
    <TableContainer mb={4} component={Paper}>
      {subscriptions.length > 0 &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Amount</TableCell>
          <TableCell align="right">Plan Name</TableCell>
          <TableCell align="right">Balance</TableCell>
          <TableCell align="right">Status</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subscriptions.map((subscription) => (
          <TableRow
            key={subscription.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            &#8358;{formatPrice(subscription.amount)}/Month
            </TableCell>
            <TableCell align="right">{subscription.plan.name}</TableCell>
            <TableCell align="right">&#8358;{formatPrice(subscription.balance)}</TableCell>
            <TableCell color={subscription.status === 'approved'? 'success': 'error'} align="right">{subscription.status}</TableCell>
            <TableCell align="right">{getDate(subscription.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </div>
  );
}