import React, {useEffect, useState} from 'react';

const SecondTimer = (props) => {
    const {time} = props;

    const countDownDate = new Date(time);
    const [text, setText] = useState('');
    const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'short' });

    useEffect(() => {
        var timer = setInterval(function() {
            var now = new Date().getTime();
            var distance =     now - countDownDate;
            
          
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    
            if ((distance/60000) > 59) {
                setText(`Over an hour`);
            }else{
                setText(`${parseInt(distance/60000)} min. ago`)
            }
            

          }, 1000);

        return () => {
            clearInterval(timer)
        };
    }, [time]);

    // Update the count down every 1 second
    

  return (
    <div>
        {text}
    </div>
  )
}

export default SecondTimer
