import React, {useEffect, useState, Fragment} from 'react'
import { Button, CardActions } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from "../../request/http-common";
import Switch from '@mui/material/Switch';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';


const PushNotification = () => {
  const [canNotify, setCanNotify] = useState(false);
  const [pushData, setPushData] = useState(null);
  const [subed, setSubbed] = useState(false);

  const { isLoading: isStoring, mutate: storeOrder } = useMutation(
    async () => {
      return await apiClient.post(`/api/subscribe-notification`, {
        subData: pushData
      });
    },
    {
      onSuccess: (res) => {
          if (res.data.status === 'success') {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            setTimeout(() => {
              const event = new Event('newMessage');
              window.dispatchEvent(event);
            }, 1000);  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            setSubbed(true);
          }            
      },
      onError: (err) => {
        let errorMessage = err.response?.data || err ;
        localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
        const event = new Event('newMessage');
        window.dispatchEvent(event);
        const event4 = new Event('processed');
        window.dispatchEvent(event4);
        
      },
    }
  );

  function initSW() {
    if (!"serviceWorker" in navigator) {
        //service worker isn't supported
        return;
    }
    if (!"PushManager" in window) {
        //push isn't supported
        return;
    }
  
  initPush();
  }

  const triggerNotificationPrompt = () =>{

    Notification.requestPermission(function(result){
      if (result !== 'granted') {
          setSubbed(false)
      }else{
          subscribeUser();               
      }
      });
  }


  // request for permission
  function initPush() {
    if (!navigator.serviceWorker.ready) {
        return;
    }
    setCanNotify(true);
  var reg;
  navigator.serviceWorker.ready.then(function(swReg){
      reg = swReg;
      return swReg.pushManager.getSubscription();
  }).then(function(sub){
      if (sub === null) {
          if ('Notification' in window) {
            setSubbed(false);
          }
      }else{
        setSubbed(true)
      }
  });
  
  }


  // public key encryption
  function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
  
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
  
    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  
  // subscribe a user to notifications
  function subscribeUser() {
    navigator.serviceWorker.ready
        .then((registration) => {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    'BE2u3JzkXpiOYIIvv6esljeFPAsJoQol8snFELlx9SQo-2TqsUHvp0Db13WwhMiOttnT4_HBrRPm91htQICSLc8'
                )
            };
            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then((pushSubscription) => {
            setPushData(pushSubscription)
            const event3 = new Event('processing');
            window.dispatchEvent(event3);

            setTimeout(() => {
              storeOrder();
            }, 1000);
        });
  }


  useEffect(() => {
    initSW();   

  }, []);
  return (
    <Fragment>
      {canNotify &&
        <ListItem>
          <ListItemIcon>
          <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText id="switch-list-label-bluetooth" primary={isStoring? 'Configuring Notification ...': 'Enable Notifications'} />
          <Switch
          edge="end"
          onChange={()=>triggerNotificationPrompt()}
          checked={subed ? true : false}
          inputProps={{
              'aria-labelledby': 'switch-list-label-bluetooth',
          }}
          />
        </ListItem>
}
    </Fragment>
  )
}

export default PushNotification
