import React, {useState, useEffect, Fragment} from 'react';
import './App.css';
import Header from './layouts/Header';
import SideBar from './layouts/SideBar';
import Footer from './layouts/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SideBarDesktop from './layouts/SideBarDesktop';
import { Routes, Route } from "react-router-dom";
import Index from './components/Home/Index';
import Grid from '@mui/material/Grid';
import Product from './components/Products/Product';
import SubscriptionPlans from './components/Subscriptions/Subscriptions';
import Wallet from './components/Wallet/Wallet';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import LoadProducts from './request/Products/LoadProducts';
import LoadPlans from './request/Plans/LoadPlans';
import Featured from './request/Featured/Featured';

import PlateContent from './components/Plates/PlateContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ProductList from './components/Featured/ProductList';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import { useSelector } from 'react-redux';
import CheckAuth from './request/Auth/CheckAuth';
import LoadFundings from './request/Funding/LoadFundings';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadSubscriptions from './request/Subscriptions/LoadSubscriptions';
import LoadDining from './request/Dining/LoadDining';
import Dining from './components/Dinning/Dining';
import Breakfasts from './components/Breakfast/Breakfasts';
import Lunches from './components/Lunch/Lunches';
import Dinners from './components/Dinner/Dinners';
import CategoryProducts from './components/Products/CategoryProducts';
import AllProducts from './components/Products/AllProducts';
import MissingRoute from './components/MissingRoute';
import ListNotifications from './components/Notifications/ListNotifications';
import FetchNotifications from './request/Notifications/FetchNotifications';
import Profile from './components/Settings/Profile';

// admin routes
import UploadProducts from './components/Admin/UploadProducts';
import ListUsers from './components/Admin/ListUsers';
import ListPlates from './components/Admin/ListPlates';
import MealSetter from './components/Admin/MealSetter';
import LunchSetter from './components/Admin/LunchSetter';
import DinnerSetter from './components/Admin/DinnerSetter';
import FeaturedSetter from './components/Admin/FeaturedSetter';
import CreatePlans from './components/Admin/CreatePlans';
import CreateCategories from './components/Admin/CreateCategories';
import ListSubscriptions from './components/Admin/UsersSubscriptions';
import EditProducts from './components/Admin/EditProducts';
import PushNotification from './components/Settings/PushNotification';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRedirect } from './components/Auth/historySlice';
import Referrals from './components/Referrals/Referrals';
import LoadReferrals  from './request/Referrals/LoadReferrals';
import ListProducts from './components/Admin/ListProducts';
import TransactionList from './components/Admin/TransactionList';
import AdminMail from './components/Admin/Mail/AdminMail';
import UserDashboard from './components/Admin/User/UserDashboard';
import ListTransfer from './components/Admin/User/ListTranfers';
import Forgot from './components/Auth/Forgot';
import UpdatePassword from './components/Auth/UpdatePassword';
import AdminSms from './components/Admin/Mail/AdminSms';
import FetchAnalysis from './request/Analysis/FetchAnalysis';
import AdminAnalysis from './components/Admin/AdminAnalysis';
import ChartAnalysis from './request/Analysis/ChartAnalysis';
import FetchMessages from './request/Messages/FetchMessages';
import ShowMessage from './components/Message/ShowMessage';
import CreateMessage from './components/Admin/CreateMessage';
import TodaysExpenses from './components/Admin/TodaysExpenses';
import Invitation from './components/Home/Invitation';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const queryClient = new QueryClient();

function App() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleToggle2 = () => {
    setOpen2(!open2);
  };

  const user = useSelector((state) => state.user.user);
  const mode = useSelector((state) => state.mode.mode);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userRole = useSelector((state) => state.user.currentRole);

  
  const [message, setMessage] = useState(null);

  const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
      };
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
  };
  
  const darkTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#24a368',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      custom: {
        main: '#fff'
      },
      secondary: {
        // light: '#0066ff',
        main: '#e38908',
        // dark: will be calculated from palette.secondary.main,
        // contrastText: '#ffcc00',
      },
  
    },
    typography: {
      fontFamily: ['Fira Sans', ' sans-serif'].join(','),
    },
  });

  useEffect(() => {
   
    window.addEventListener('newMessage', function (e) {
      const notification = JSON.parse(localStorage.getItem('notification'));
      if (notification) {
        
        if (notification.message === 'Unauthenticated.') {
          let pathName = window.location.pathname;
          dispatch(setRedirect(pathName));
          setMessage(notification);
          handleClick();
          setTimeout(() => {
            navigate('/login');
          }, 1000);
          
        }else{
          setMessage(notification);
          handleClick();
  
        }
      }
  }, false); 

  window.addEventListener('processing', function (e) {
    handleToggle2();
}, false); 
window.addEventListener('processed', function (e) {
  handleClose2();
}, false);

  }, [user]);

  if (isAuth) {
    return (
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <CheckAuth/>
          <LoadProducts/>
          <LoadPlans/>
          <LoadReferrals/>
          <Featured/>          
          <LoadFundings/>
          <FetchMessages/>
          <LoadSubscriptions/>
          <LoadDining/>
          <FetchNotifications/>
          <ShowMessage/>
          
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header/>
        <SideBar/>
          <Grid container spacing={0}>
            <Grid sx={{display: {md: 'flex', sm: 'none', xs: 'none'}}} item md={2}>
            <SideBarDesktop/>
            </Grid>
            <Grid item xs={12} md={10}>
              <Routes>   
                {userRole === 'admin' ? <Route path='/' element={<AdminAnalysis/>} /> : <Route path='/' element={<Index/>} /> }
                <Route path='/product' element={<Product/>} />
                <Route path='/subscriptions' element={<SubscriptionPlans/>} />
                <Route path='/wallet' element={<Wallet/>} />
                <Route path='/plate-content' element={<PlateContent/>} />
                <Route path='/featured' element={<ProductList/>} />
                <Route path='/dining' element={<Dining/>} />
                <Route path='/today-breakfast' element={<Breakfasts/>} />
                <Route path='/today-lunch' element={<Lunches/>} />
                <Route path='/today-dinner' element={<Dinners/>} />
                <Route path='/products' element={<AllProducts/>} />
                <Route path='/notifications' element={<ListNotifications/>} />
                <Route path='/profile' element={<Profile/>} />
                <Route path='/referrals' element={<Referrals/>} />
                <Route path='/push-notification' element={<PushNotification/>} />
                <Route path='/category/:category' element={<CategoryProducts/>} />               
                
                {/* admin routes */}

                {userRole === 'admin' &&
                <Fragment>
                  
                  <Route path='/upload' element={<UploadProducts/>} />
                  <Route path='/send-mail' element={<AdminMail/>} />
                  <Route path='/send-sms' element={<AdminSms/>} />
                  <Route path='/admin-analysis' element={<AdminAnalysis/>} />
                  <Route path='/admin-message' element={<CreateMessage/>} />
                  
                <Route path='/transaction-list' element={<TransactionList/>} />
                <Route path='/list-users' element={<ListUsers/>} />
                <Route path='/list-orders' element={<ListPlates/>} />
                <Route path='/meal-setter' element={<MealSetter/>} />
                <Route path='/set-lunch' element={<LunchSetter/>} />
                <Route path='/set-dinner' element={<DinnerSetter/>} />
                <Route path='/set-featured' element={<FeaturedSetter/>} />
                <Route path='/create-plans' element={<CreatePlans/>} />
                <Route path='/create-categories' element={<CreateCategories/>} />
                <Route path='/list-subscriptions' element={<ListSubscriptions/>} />
                <Route path='/list-products' element={<ListProducts/>} />
                <Route path='/user-dashboard/:id' element={<UserDashboard/>} />                
                <Route path='/edit-product/:id' element={<EditProducts/>} /> 
                <Route path='/list-transfers' element={<ListTransfer/>} />               
                <Route path='/list-expenses' element={<TodaysExpenses/>} />               
                
                </Fragment>
                }

                {userRole === 'dispatch rider' &&
                <Fragment>
                <Route path='/list-orders' element={<ListPlates/>} />
                </Fragment>
                }

                {userRole === 'editor' &&
                <Fragment>
                <Route path='/list-orders' element={<ListPlates/>} />
                <Route path='/upload' element={<UploadProducts/>} />
                <Route path='/meal-setter' element={<MealSetter/>} />
                <Route path='/set-lunch' element={<LunchSetter/>} />
                <Route path='/set-dinner' element={<DinnerSetter/>} />
                <Route path='/set-featured' element={<FeaturedSetter/>} />
                <Route path='/create-categories' element={<CreateCategories/>} />
                <Route path='/edit-product/:id' element={<EditProducts/>} />  
                <Route path='/list-products' element={<ListProducts/>} /> 
                <Route path='/list-transfers' element={<ListTransfer/>} /> 
                           
                </Fragment>
                }
                <Route path="*" element={<MissingRoute/>} />
              </Routes>
            </Grid>
          </Grid>
        <Footer/>
        {message !== null &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message.type} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        </Snackbar>
        }
        {userRole === 'admin' && 
          <Fragment>
            <FetchAnalysis/>
            <ChartAnalysis/>
          </Fragment>        
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
          <CircularProgress color="inherit" />
      </Backdrop>

        </ThemeProvider>
        </QueryClientProvider>
      </div>
    );
  }else{
    return (
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <CheckAuth/>
          <LoadProducts/>
          <LoadPlans/>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header/>
        <SideBar/>
          <Grid container spacing={0}>
            <Grid sx={{display: {md: 'flex', sm: 'none', xs: 'none'}}} item md={2}>
            <SideBarDesktop/>
            </Grid>
            <Grid item xs={12} md={10}>
              <Routes>   
                <Route path='/' element={<Index/>} />
                <Route path='/product' element={<Product/>} />
                <Route path='/subscriptions' element={<SubscriptionPlans/>} />
                {/* <Route path='/wallet' element={<Wallet/>} /> */}
                <Route path='/plate-content' element={<PlateContent/>} />
                <Route path='/featured' element={<ProductList/>} />
                <Route path='/dining' element={<Dining/>} />
                <Route path='/today-breakfast' element={<Breakfasts/>} />
                <Route path='/today-lunch' element={<Lunches/>} />
                <Route path='/today-dinner' element={<Dinners/>} />
                <Route path='/products' element={<AllProducts/>} />
                <Route path='/notifications' element={<ListNotifications/>} />
                <Route path='/push-notification' element={<PushNotification/>} />
                <Route path='/category/:category' element={<CategoryProducts/>} />               
                <Route path='/' element={<Index/>} />
                <Route path='/register' element={<Register/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/forgot' element={<Forgot/>} />
                <Route path='/update-password' element={<UpdatePassword/>} />
                <Route path='/invitation' element={<Invitation/>} />

                
                {/* admin routes */}              
                <Route path="*" element={<MissingRoute/>} />
              </Routes>
            </Grid>
          </Grid>
        <Footer/>
        {message !== null &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message.type} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        </Snackbar>
        }
        
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
          <CircularProgress color="inherit" />
      </Backdrop>

        </ThemeProvider>
        </QueryClientProvider>
      </div>
    )
  }
  
}

export default App;
