import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    todaysProfit: localStorage.getItem('todays-profit') || 0,
    amountSoldToday: localStorage.getItem('amount-sold-today') || 0,
    todaysFundings: localStorage.getItem('todays-fundings') || 0,
    todaysExpenses: localStorage.getItem('todays-expenses') || 0,

    totalSales: localStorage.getItem('total-sales') || 0,
    totalFundings: localStorage.getItem('total-fundings') || 0,
    totalUsers: localStorage.getItem('total-users') || 0,
    totalExpenses: localStorage.getItem('total-expenses') || 0,

    product_qty_sold: JSON.parse(localStorage.getItem('product_qty_sold')) || [],
    product_table_name: JSON.parse(localStorage.getItem('product_table_name')) || [],
    
}

export const unitAnalysislice = createSlice({
    name: 'unitAnalysis',
    initialState,
    reducers: {
        setTodaysProfit: (state, action) =>{
            state.todaysProfit = action.payload;  
            localStorage.setItem('todays-profit', state.todaysProfit);
        },

        setAmountSoldToday: (state, action) =>{
            state.amountSoldToday = action.payload;  
            localStorage.setItem('amount-sold-today', state.amountSoldToday);
        },
        
        setTodaysFundings: (state, action) =>{
            state.todaysFundings = action.payload;  
            localStorage.setItem('todays-fundings', state.todaysFundings);
        },

        setTodaysExpenses: (state, action) =>{
            state.todaysExpenses = action.payload;  
            localStorage.setItem('todays-expenses', state.todaysExpenses);
        },
         
        setTotalSales: (state, action) =>{
            state.totalSales = action.payload;  
            localStorage.setItem('total-sales', state.totalSales);
        },
        setTotalFundings: (state, action) =>{
            state.totalFundings = action.payload;  
            localStorage.setItem('total-fundings', state.totalFundings);
        },
        setTotalUsers: (state, action) =>{
            state.totalUsers = action.payload;  
            localStorage.setItem('total-users', state.totalUsers);
        },

        setTotalExpenses: (state, action) =>{
            state.totalExpenses = action.payload;  
            localStorage.setItem('total-expenses', state.totalExpenses);
        },
        setQuantityProductsSold: (state, action) =>{
            state.product_qty_sold = action.payload;  
            localStorage.setItem('product_qty_sold', JSON.stringify(state.product_qty_sold));
        },
        setNameProductsSold: (state, action) =>{
            state.product_table_name = action.payload;  
            localStorage.setItem('product_table_name', JSON.stringify(state.product_table_name));
        }
    }
})

export const {setTodaysProfit,
             setAmountSoldToday,
             setTodaysFundings,
             setTotalSales,
             setTotalFundings,
             setTotalUsers,
             setTotalExpenses,
             setTodaysExpenses,
             setQuantityProductsSold,
             setNameProductsSold

            } = unitAnalysislice.actions;
            
export default unitAnalysislice.reducer;