import React, {useState} from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

import { useMutation } from "react-query";
import apiClient from "../../request/http-common";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { setFundingBal, setFundings, setSubBalance } from './walletSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRedirect } from '../Auth/historySlice';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BadgeIcon from '@mui/icons-material/Badge';
import { CardActions } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WalletButton = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const [buttonText, setbuttonText] = useState(null);
    const [numberText, setNumberText] = useState(null);

    const dispatch = useDispatch();
  const initialFunding = {
    amount: 0,
    gateway: '',
    transaction_id: ''
  }

  const [funding, setFunding] = useState(initialFunding);

  const { isLoading: isFuding, mutate: postFunding } = useMutation(
    async () => {
      return await apiClient.post(`/api/store-funding`, {
        amount: funding.amount,
        gateway: funding.gateway,
        transaction_id: funding.transaction_id
      });
    },
    {
      onSuccess: (res) => {
          if (res.data.status === 'success') {

            dispatch(setFundingBal(res.data.balance))
            dispatch(setFundings(res.data.fundings))
            dispatch(setSubBalance(res.data.subscriptionBalance))

            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            handleClose2();
            
          }            
      },
      onError: (err) => {
            let errorMessage = err.response?.data || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            handleClose2();
      },
    }
  );


  const { isLoading: isTransfering, mutate: postTransfer } = useMutation(
    async () => {
      return await apiClient.post(`/api/save-transfer`, {
        user_id: user.id,
        transaction_ref: user.phone
      });
    },
    {
      onSuccess: (res) => {
          if (res.data.status === 'success') {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            
            
          }            
      },
      onError: (err) => {
            let errorMessage = err.response?.data.message || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            handleClose();
            
      },
    }
  );

  const [amount, setAmount] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (isAuth && user.name) {
      setOpen(true);
    }else{
      let path = window.location.pathname;
      dispatch(setRedirect(path));
      navigate('/login');
    }
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleToggle2 = () => {
    setOpen2(!open2);
  };

      const config = {
        public_key: 'FLWPUBK-93614a5a82425ce9108619dce848cd5b-X',
        tx_ref: Date.now(),
        amount: amount,
        currency: 'NGN',
        payment_options: 'bank_transfer',
        customer: {
          email: user.email,
           phone_number: user.phone,
          name: user.name,
        },
        customizations: {
          title: 'Mnubar Kitchen Payment',
          description: 'Account Funding',
          logo: 'https://res.cloudinary.com/dizxmpzej/image/upload/v1677103131/menubaricon1024_gskipq.png',
        },
      };
    
      const handleFlutterPayment = useFlutterwave(config);

  return (
    <div>
      
      <Button onClick={handleClickOpen} variant="contained" size='large' color="primary">
                {isFuding? 'Processing Payment...' : 'Fund Wallet'}
              </Button>
      <Dialog
        sx={{zIndex: 40000}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle sx={{textAlign: 'center'}}>{"FUND YOUR WALLET"}</DialogTitle> */}

        <DialogContent>

          {/* <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
            Kindly enter any amount and proceed to pay
          </DialogContentText>

          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>
            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="amount">Enter Amount To Fund</InputLabel>
              <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                id="amount"
                type={'number'}
                label="Enter Amount To Fund"
              />
            </FormControl>
          </Box> */}

          {amount < 1 &&
          <List
          subheader={<ListSubheader mt={4} sx={{zIndex: 0, marginTop: '20px', position: 'relative', justifyContent: 'center'}}> PAY ANY AMOUNT TO OUR ACCOUNT BELOW 👇</ListSubheader>}
          >

              <CopyToClipboard onCopy={() => {setbuttonText('account number copied!');
                  setTimeout(() => {
                      setbuttonText(null);
                  }, 2000);}} text='9074629800'>
                      <ListItem disablePadding>
                          <ListItemButton>
                          <ListItemIcon>
                              <ContentCopyIcon />
                          </ListItemIcon>
                          <ListItemText primary='9074629800' secondary={buttonText !== null? buttonText: 'Account Number'} />
                          </ListItemButton>
                      </ListItem>
              </CopyToClipboard>

              <ListItem disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                      <BadgeIcon />
                  </ListItemIcon>
                  <ListItemText primary='MENUBAR KITCHEN LIMITED' secondary='Account Name' />
                  </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                      <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText primary='MONIEPOINT MICROFINANCE BANK' secondary='Bank Name' />
                  </ListItemButton>
              </ListItem>

              <CopyToClipboard onCopy={() => {setNumberText('description copied!');
                  setTimeout(() => {
                    setNumberText(null);
                  }, 2000);}} text={user.phone}>
                      <ListItem disablePadding>
                          <ListItemButton>
                          <ListItemIcon>
                              <ContentCopyIcon />
                          </ListItemIcon>
                          <ListItemText primary={user.phone} secondary={numberText !== null? numberText: 'Transaction Description'} />
                          </ListItemButton>
                      </ListItem>
              </CopyToClipboard> 

              <CardActions>
                  <Button onClick={postTransfer} fullWidth={true} size="large" color="primary" variant="contained"> 
                  {isTransfering ? 'confirming...' : 'I have completed this payment'}
                  </Button>
              </CardActions>
          </List>
          }          
          
        </DialogContent>
        
        <DialogActions>
          {amount > 0 &&
          <Button onClick={() => {
                  handleFlutterPayment({
                    callback: (response) => {

                      if(response.status === 'completed'){
                        setFunding({...funding,
                          amount: response.amount,
                          gateway: 'flutterwave',
                          transaction_id: response.tx_ref,
                        });
                        handleToggle2();
                        setTimeout(() => {
                          postFunding();
                        }, 2000);
                      }

                      closePaymentModal() 
                    },
                    onClose: () => {
                      localStorage.setItem('notification', JSON.stringify({message : `Transaction terminated!`, 'type': 'error' }));
                      const event = new Event('newMessage');
                      window.dispatchEvent(event);
                    },
                  });

                  handleClose();
                }} color='error'>Proceed to pay</Button>
                }
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open2}
      >
      <CircularProgress color="inherit" />
      <Typography my={2} variant="body2" color="success">
          Processing Payment...!
      </Typography>
      </Backdrop>
    </div>
  )
}

export default WalletButton
