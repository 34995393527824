import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    salesAnalysis: JSON.parse(localStorage.getItem('sales-analysis')) || [],
    usersAnalysis: JSON.parse(localStorage.getItem('users-analysis')) || [],
    fundingsAnalysis: JSON.parse(localStorage.getItem('fundings-analysis')) || [],
    referralsAnalysis: JSON.parse(localStorage.getItem('referrals-analysis')) || [],
    profitAnalysis: JSON.parse(localStorage.getItem('profit-analysis')) || [],
    expensesAnalysis: JSON.parse(localStorage.getItem('expenses-analysis')) || []
    
}

export const chartsSlice = createSlice({
    name: 'chartsAnalysis',
    initialState,
    reducers: {
        setSalesAnalysis: (state, action) =>{
            state.salesAnalysis = action.payload;  
            localStorage.setItem('sales-analysis', JSON.stringify(state.salesAnalysis));
        },
        setUsersAnalysis: (state, action) =>{
            state.usersAnalysis = action.payload;  
            localStorage.setItem('users-analysis', JSON.stringify(state.usersAnalysis));
        },
        setFundingsAnalysis: (state, action) =>{
            state.fundingsAnalysis = action.payload;  
            localStorage.setItem('fundings-analysis', JSON.stringify(state.fundingsAnalysis));
        },
        setReferralsAnalysis: (state, action) =>{
            state.referralsAnalysis = action.payload;  
            localStorage.setItem('referrals-analysis', JSON.stringify(state.referralsAnalysis));
        },

        setProfitAnalysis: (state, action) =>{
            state.profitAnalysis = action.payload;  
            localStorage.setItem('profit-analysis', JSON.stringify(state.profitAnalysis));
        },

        setExpensesAnalysis: (state, action) =>{
            state.expensesAnalysis = action.payload;  
            localStorage.setItem('expenses-analysis', JSON.stringify(state.expensesAnalysis));
        },
    }
})

export const {setSalesAnalysis,
        setUsersAnalysis,
        setFundingsAnalysis,
        setReferralsAnalysis,
        setExpensesAnalysis,
        setProfitAnalysis} = chartsSlice.actions;
export default chartsSlice.reducer;