import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setUsersAnalysis,
        setSalesAnalysis,
        setFundingsAnalysis,
        setReferralsAnalysis,
        setProfitAnalysis,
        setExpensesAnalysis } from '../../components/Analysis/chartsSlice';

export const ChartAnalysis = () => {

  const dispatch = useDispatch();

    const { isLoading: isLoadingFundings, refetch: getAnalysis } = useQuery(
        "charts-analysis",
        async () => {
          return await apiClient.get("/api/charts-analysis");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setUsersAnalysis(res.data.users_analysis))
                dispatch(setSalesAnalysis(res.data.sales_analysis))
                dispatch(setFundingsAnalysis(res.data.fundings_analysis))
                dispatch(setReferralsAnalysis(res.data.referral_analysis))
                dispatch(setProfitAnalysis(res.data.profit_analysis))
                dispatch(setExpensesAnalysis(res.data.expenses_analysis))
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getAnalysis();
      }, []);
 
}

export default ChartAnalysis
