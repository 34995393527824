import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import {
     setTodaysProfit,
     setAmountSoldToday,
     setTodaysFundings,
     setTotalSales,
     setTotalFundings,
     setTotalUsers,
     setTotalExpenses,
     setTodaysExpenses,
     setNameProductsSold,
     setQuantityProductsSold
      } from '../../components/Analysis/analysisSlice';

export const FetchAnalysis = () => {

  const dispatch = useDispatch();

    const { isLoading: isLoadingFundings, refetch: getAnalysis } = useQuery(
        "units-analysis",
        async () => {
          return await apiClient.get("/api/units-analysis");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setTodaysProfit(res.data.todays_profits))
                dispatch(setAmountSoldToday(res.data.amount_sold_today))
                dispatch(setTodaysFundings(res.data.todays_fundings))
                dispatch(setTotalSales(res.data.total_sales))
                dispatch(setTotalFundings(res.data.total_fundings))
                dispatch(setTotalUsers(res.data.total_users))
                dispatch(setTotalExpenses(res.data.total_expenses))
                dispatch(setTodaysExpenses(res.data.todays_expenses))
                dispatch(setQuantityProductsSold(res.data.product_qty_sold))
                dispatch(setNameProductsSold(res.data.product_table_name))
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getAnalysis();
      }, []);
 
}

export default FetchAnalysis
