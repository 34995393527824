import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dinings: JSON.parse(localStorage.getItem('dinings')) || [],
}

export const diningdSlice = createSlice({
    name: 'dinings',
    initialState,
    reducers: {
        setDinings: (state, action) =>{
            state.dinings = action.payload;  
            localStorage.setItem('dinings', JSON.stringify(state.dinings));
        }       
    }
})

export const {setDinings} = diningdSlice.actions;
export default diningdSlice.reducer;