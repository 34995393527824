import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';

const CreatePlans = () => {
    const [plans, setplans] = useState(null);
    const { isLoading: isLoadingplans, refetch: getplans } = useQuery(
        "plans",
        async () => {
          return await apiClient.get("/api/plans");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setplans(res.data.plans);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialLogin = {
        name: '',
        amount: '',
        min_amount: '',
        max_amount: '',
        duration: '',
        num_meals: '',
        delivery_fee: '',
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
          return await apiClient.post(`/api/store-plan`, {
            name: planForm.name,
            amount: planForm.amount,
            min_amount: planForm.min_amount,
            max_amount: planForm.max_amount,
            duration: planForm.duration,
            num_meals: planForm.num_meals,
            delivery_fee: planForm.delivery_fee,
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

      useEffect(() => {
        getplans();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        CREATE MEAL SUBSCRIPTION PLANS
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create subscription plans
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Plan Name">Plan Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Plan Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Plan Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="amount">Amount</InputLabel>
                            {(error !== '' && error.amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="amount"
                            type={'number'}
                            name="amount"
                            label="Amount"
                            helperText={error.amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="amount"
                            type={'number'}
                            name="amount"
                            label="Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.amount}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="min_amount">Min Amount</InputLabel>
                            {(error !== '' && error.min_amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="min_amount"
                            type={'number'}
                            name="min_amount"
                            label="Min Amount"
                            helperText={error.min_amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="min_amount"
                            type={'number'}
                            name="min_amount"
                            label="Min Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.min_amount}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="max_amount">Max Amount</InputLabel>
                            {(error !== '' && error.max_amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="max_amount"
                            type={'number'}
                            name="max_amount"
                            label="Max Amount"
                            helperText={error.max_amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="max_amount"
                            type={'number'}
                            name="max_amount"
                            label="Max Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.max_amount}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="duration">Duration</InputLabel>
                            {(error !== '' && error.duration) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="duration"
                            name="duration"
                            type={'text'}
                            label="Duration"
                            helperText={error.duration}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="duration"
                            name="duration"
                            type={'text'}
                            label="Duration"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.duration}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="num_meals">Number of Meals</InputLabel>
                            {(error !== '' && error.num_meals) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="num_meals"
                            name="num_meals"
                            type={'number'}
                            label="Number of Meals"
                            helperText={error.num_meals}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="num_meals"
                            name="num_meals"
                            type={'number'}
                            label="Number of Meals"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.num_meals}
                            </Typography>
                        </FormControl>

                        <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="delivery_fee">Delivery Fee</InputLabel>
                            {(error !== '' && error.delivery_fee) ?
                            <OutlinedInput
                            error
                            helperText={error.delivery_fee}
                            onChange={onChange}
                            id="delivery_fee"
                            name="delivery_fee"
                            type={'number'}
                            label="Delivery Fee"
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="delivery_fee"
                            name="delivery_fee"
                            type={'number'}
                            label="Delivery Fee"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.delivery_fee}
                            </Typography>
                        </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Creating...' : 'Create Plan'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreatePlans
